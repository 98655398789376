import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import { RESTRICTED_IP_CREATE_STATUS, RESTRICTED_IP_MODIFY_TYPE } from "@/constant/systemManagerStatus";
const queryString = require('query-string');



// Constant
import { API_CALL_STATUS } from "@/constant/config";
import { SETTINGS_SYSTEM_MANAGEMENT_API } from "@/constant/apiSettings";

// Extend
import base from '@/scripts/base';

// Api
import api from '@/api/baseApi';

// Component 
import BlacklistRuleModify from "@/views/modal/systemManager/blacklistRule/BlacklistRuleModify.vue";
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";

export default {

  components: {
    BlacklistRuleModify,
    CaretTop,
    CaretBottom,
  },

  extends: base,

  data() {
    return {
      RESTRICTED_IP_MODIFY_TYPE: RESTRICTED_IP_MODIFY_TYPE,

      isCheckAll: false,
      listData: [],
      pagination: {
        status: 1,
        querySearch: '',
        orderBy: 'A.ID',
        directionSort: 'DESC',
        pageIndex: 1,
        pageSize: 50,
        outRowsNumber: 0,
      },

      isCreating: false,
      ip: null,
      description: null,

      modalCenterRestrictedIpModify: "",
      restrictedIpModifyKey: 0,
      selectedList: [],

      target: null,
    };
  },

  computed: {
    ...mapState({
      listStaff: state => state.staffs,
      listServicePack: state => state.common.listServicePack,
      decentralization: state => state.auth.decentralization,
    }),
  },

  created() {
    EventBus.$on("openPopup", this.openPopup);
    EventBus.$on("closePopup", this.closePopup);

    this.getData();
  },

  mounted() {

  },

  methods: {

    // Processing event
    processingEvent(param) {
      this[param.event](param.data);
    },

    // Open pop-up
    openPopup(param) {
      $(`#${param.type}`).modal({ backdrop: "static" });
      this.target = param.data;
    },

    // Close pop-up
    closePopup(popup) {
      $(`#${popup}`).modal("hide");
    },

    // Selected all item
    selectedAll() {
      this.listData.forEach(element => {
        element.isChecked = this.isCheckAll;
      });
    },

    // Selected item
    selectedItem() {
      if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    // Action
    performAction(param) {
      switch (param.action) {
        case 'refresh':
          this.getData(this.pagination);
          break;
        case 'copyToClipboard':
          this.copyToClipboard(param.data.inputId, param.data.data);
          break;
      }
    },

    // Search list
    searchList() {
      this.pagination.status = 0;
      if (this.pagination.querySearch) {
        this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
      }
      this.getData();
    },

    // Sort list
    sortList(element) {
      this.pagination.pageIndex = 1;
      this.pagination.orderBy = element;
      if (this.pagination.directionSort === 'ASC') {
        this.pagination.directionSort = 'DESC'
      } else {
        this.pagination.directionSort = 'ASC'
      }
      this.getData();
    },

    // Pagination size change
    handleSizeChange(pageSize) {
      this.pagination.pageIndex = 1;
      this.pagination.pageSize = pageSize;
      this.getData();
    },

    // Pagination page change
    handleCurrentChange(element) {
      this.pagination.pageIndex = element;
      this.getData();
    },

    //Context menu action
    contextMenuAction(command) {
      switch (command.type) {
        case "modify":
          this.openPopup({ type: "blacklist-ip-modify", data: command.data });
          break;
        case "deactivate":
          this.modifyItem(RESTRICTED_IP_MODIFY_TYPE.DEACTIVATE, [command.data]);
          break;
        case "activate":
          this.modifyItem(RESTRICTED_IP_MODIFY_TYPE.ACTIVATE, [command.data]);
          break;
        case "remove":
          this.modifyItem([command.data]);
          break;
      }
    },

    // Get data
    getData() {
      this.feedStatusLoading(API_CALL_STATUS.LOADING);
      this.isCheckAll = false;
      api.baseRequest(SETTINGS_SYSTEM_MANAGEMENT_API.BLACKLIST_RULE_VIEW, null, `?${queryString.stringify(this.pagination)}`)
        .then((res) => {
          try {
            let responseData = res.data;
            let pagingItem = res.data.pagingItem;
            responseData.data.forEach(element => {
              element.isChecked = false;
            });
            this.listData = responseData.data;
            this.pagination.orderBy = pagingItem.orderBy;
            this.pagination.directionSort = pagingItem.directionSort;
            this.pagination.pageIndex = pagingItem.pageIndex;
            this.pagination.pageSize = pagingItem.pageSize;
            this.pagination.outRowsNumber = pagingItem.outRowsNumber;

            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
          } catch (error) {
            console.log("getData -> error", error)
            this.feedStatusLoading(API_CALL_STATUS.FAILED);
          }
        })
        .catch(error => {
          this.processCatchApiRequest(error, "Request failed ...", 5)
        });
    },

    // Modify
    modifyItems(type, listSelected) {
      this.feedStatusLoading(API_CALL_STATUS.LOADING);
      let data = {
        listData: listSelected,
        type: type,
        deviceInformation: JSON.stringify(this.getInformationClient()),
      }
      api.baseRequest(SETTINGS_SYSTEM_MANAGEMENT_API.BLACKLIST_RULE_MODIFY, data)
        .then(() => {
          try {
            this.getData();
            this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
          } catch (error) {
            console.log("getData -> error", error)
            this.feedStatusLoading(API_CALL_STATUS.FAILED);
          }
        })
        .catch(error => {
          this.processCatchApiRequest(error, "Request failed ...", 5)
        });
    },

    // Delete
    deleteItems(listSelected) {
      this.feedStatusLoading(API_CALL_STATUS.LOADING);
      let data = {
        listData: listSelected,
        deviceInformation: JSON.stringify(this.getInformationClient()),
      }
      api.baseRequest(SETTINGS_SYSTEM_MANAGEMENT_API.BLACKLIST_RULE_DELETE, data)
        .then((res) => {
          try {
            this.getData();
            this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
          } catch (error) {
            console.log("getData -> error", error)
            this.feedStatusLoading(API_CALL_STATUS.FAILED);
          }
        })
        .catch(error => {
          this.processCatchApiRequest(error, "Request failed ...", 5)
        });
    },
  }
}