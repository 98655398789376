// Constant
import { API_CALL_STATUS } from "@/constant/config";
import { POPUP_ID } from "@/constant/baseConstant";
import { SETTINGS_SYSTEM_MANAGEMENT_API } from "@/constant/apiSettings";

// Extend
import base from '@/scripts/base';

// Api
import api from '@/api/baseApi';

// Component 
import Decentralized from "@/views/components/systemManager/Decentralized.vue";

// Function 
const queryString = require('query-string');

export default {

  components: {
    Decentralized,
  },

  extends: base,

  data() {
    return {
      functionalGroups: [],
      decentralized: [],

      name: null,
      description: null,
    };
  },

  props: {
    element: {
      type: Object,
      default: null
    },
  },

  computed: {
  },

  created() {

  },

  mounted() {
  },

  watch: {
    element: function (newVal, oldVal) {
      if (newVal) {
        this.getData();
        this.name = newVal.name;
        this.description = newVal.description;
      } else {
        /* empty */
      }
    }
  },

  methods: {
    //#region Close this pop-up
    closeThisPopup() {
      this.closePopup(POPUP_ID.BLACKLIST_RULE_MODIFY);
    },
    //#endregion

    // Get data
    getData() {
      this.feedStatusLoading(API_CALL_STATUS.LOADING);
      let data = {
        id: this.element.id
      }
      api.baseRequest(SETTINGS_SYSTEM_MANAGEMENT_API.BLACKLIST_RULE_DETAILS, null, `?${queryString.stringify(data)}`)
        .then((res) => {
          try {
            let responseData = res.data;
            this.functionalGroups = responseData.functionalGroups;
            this.decentralized = responseData.decentralized;
            this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
          } catch (error) {
            console.log("getData -> error", error)
            this.feedStatusLoading(API_CALL_STATUS.FAILED);
          }
        })
        .catch(error => {
          this.processCatchApiRequest(error, "Request failed ...", 5)
        });
    },

    // Modify rule
    modifyItem() {
      this.feedStatusLoading(API_CALL_STATUS.LOADING);
      let data = {
        ruleId: this.element.id,
        listData: this.decentralized.filter(x => x.enabled),
        name: this.name,
        description: this.description,
        type: 3,
        deviceInformation: JSON.stringify(this.getInformationClient()),
      }
      setTimeout(() => {
        api.baseRequest(SETTINGS_SYSTEM_MANAGEMENT_API.BLACKLIST_RULE_MODIFY, data)
          .then(() => {
            try {
              this.closeThisPopup();
              this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
            } catch (error) {
              console.log("getData -> error", error)
              this.feedStatusLoading(API_CALL_STATUS.FAILED);
            }
          })
          .catch(error => {
            this.processCatchApiRequest(error, "Request failed ...", 5)
          });
      }, this.smoothTimeOut);
    },
  },
}